import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'
import { useTranslation } from 'react-i18next-new'
import useRoutes from '../../../hooks/useRoutes'
import { Divider, SmallPageSection } from '../../reusable_components/layout/PageComponents'
import LanguageSwitcher from './LanguageSwitcher'

const FooterWrapper = styled.div`
	padding: ${theme.paddings.large} 0 ${theme.paddings.large};
	${theme.breakpoints.tablet.upToAndIncluding} {
		p {
			font-size: ${theme.fontSizes.p2};
			line-height: ${theme.lineHeights.p2};
		}
		a {
			font-size: ${theme.fontSizes.p3};
			line-height: ${theme.lineHeights.p3};
		}
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		p {
			font-size: ${theme.fontSizes.p1};
			line-height: ${theme.lineHeights.p1};
		}
		a {
			font-size: ${theme.fontSizes.p2};
			line-height: ${theme.lineHeights.p2};
		}
	}
	filter: ${({ blurFooter }) => (blurFooter ? `blur(100px)` : 'none')};
`

const Footer = props => {
	const { t, i18n } = useTranslation()
	const routes = useRoutes()
	return (
		<FooterWrapper id="pageFooter">
			<Divider />
			<SmallPageSection>
				<LanguageSwitcher color={theme.colors.black} />
			</SmallPageSection>
			<div>
				<a href="https://organizerhelp.ejbla.com/" target="_blank" rel="noopener">
					{t('copy:footer.helpCenterExperts')}
				</a>
				&nbsp;&nbsp;·&nbsp;&nbsp;
				<a href="https://help.ejbla.com/" target="_blank" rel="noopener">
					{t('copy:footer.faq')}
				</a>
			</div>
			<SmallPageSection>
				<a href={routes.custom.integrityPolicy.url} target="_blank" rel="nofollow noreferrer noopener">
					{t('common:documents.integrityPolicy')}
				</a>
				&nbsp;&nbsp;·&nbsp;&nbsp;
				<a href={routes.custom.termsOfUse.url} target="_blank" rel="nofollow noreferrer noopener">
					{t('common:documents.termsOfUse')}
				</a>
			</SmallPageSection>
			<div>
				<a href="mailto:help@ejbla.com" target="_blank" rel="noopener">
					{t('copy:footer.contact')}
				</a>
			</div>
			<br />
			<div>
				{new Date().getFullYear()} {t('copy:footer.copyRight')}
			</div>
		</FooterWrapper>
	)
}

export default Footer
