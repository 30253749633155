import { createGlobalStyle } from 'styled-components'
import { theme } from '../../utils/theme'

const AutoSuggestStyle = createGlobalStyle`
  .react-autosuggest__container {
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  .react-autosuggest__input {
    -webkit-appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    padding: ${theme.paddings.XSmall};
    font-weight: 300;
    border: none;
    border-radius: 2px;
    padding-left: 60px;
    background: ${theme.colors.lightGray};
  }

  .react-autosuggest__input:focus {
    outline: none;
  }

  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 75px;
    width: 100%;
    border: none;
    background: ${theme.colors.lightGray};
    font-weight: 300;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    z-index: 2;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: ${theme.paddings.XSmall} ${theme.paddings.small};
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${theme.colors.black};
    color: white;
  }

  .react-autosuggest__suggestion:last-child.react-autosuggest__suggestion--highlighted {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  .react-autosuggest__section-title {
    padding: ${theme.paddings.small} ${theme.paddings.small} ${theme.paddings.XSmall};
    font-size: ${theme.fontSizes.h2};
  }

  .react-autosuggest__more-courses-link {
    padding: ${theme.paddings.XSmall} ${theme.paddings.small};
    text-decoration: underline;
    color: ${theme.colors.lightTextColor};
    cursor: pointer;
  }
`

export default AutoSuggestStyle
